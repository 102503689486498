/* eslint-disable no-multi-str */
export interface Semester {
    id: number;
    text: string;
    thumbnail?: string;
    children?: Semester[];
}

export const Semesters: Semester[] = [
    {
        id: 2,
        text: 'Uni',
        thumbnail: 'bachelor.jpg',
        children: [
            { id: 1, text: 'UniSem1', thumbnail: 'T1.png' },
            { id: 2, text: 'UniSem2', thumbnail: 'T2.png' },
            { id: 3, text: 'UniSem3', thumbnail: 'T3.png' },
            { id: 4, text: 'UniSem4', thumbnail: 'T4.png' },
            { id: 5, text: 'UniSem5' },
            { id: 6, text: 'UniSem6', thumbnail: 'T6.png' },
        ]
    },
    {
        id: 1,
        text: 'Dip',
        thumbnail: 'saibt.jpg',
        children: [
            { id: 1, text: 'DipSem1' },
            { id: 2, text: 'DipSem2' }
        ]
    },
    {
        id: 3,
        text: 'Mast',
        thumbnail: 'master.jpg',
        children: [
            { id: 1, text: '' }
        ]
    }
]

export interface Subject {
    id: number;
    title?: string;
    imageURLs?: string[];
    thumbnail?: string;
    text?: string[];
}

interface Course {
    course_id: string;
    title?: string;
    subjects: Subject[];
}

export const Courses: Course[] = [
    {
        course_id: 'U1C1',
        title: 'Design Studio 1',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1034| - Project 1 - I AM HERE',
                thumbnail: 'T111.png',
                imageURLs: [
                    'U1C1/panel1.png', 'U1C1/panel2.png', 'U1C1/panel3.png', 'U1C1/panel4.png'
                ],
                text: [
                    'Panel 1: Measure\nHuman scale in architecture is based on the dimensions and proportions of the human body. Even though everyone’s body measurements and size are different, \
                    we can find clues within the spaces we use for typical human scaled elements. These proportions relate to the dimensions of our posture, pace, reach, or grasp and include elements \
                    such as a chair or table, the risers and treads of a stair way, the sill of a window, doorways and openings and corridors. Using my own residence as a case study, a series of measurements \
                    are to be gathered that document intimate settings and their dimensions within my home.',
                    'Panel 2: Mapping\nThis panel requires to carefully analyse and communicate my observations of the material characteristics of my residence through an assembly of photographs. The content, \
                    detail and representation of my mapping should be discussed with my studio instructor and based on patterns observed, such as texture, transparency, opacity, mass, colour and may be further \
                    informed through communicating the effects of time such as light and shadow; evidence of occupation; the accumulations of time; weathering and the proportion of materials to one another.',
                    'Panel 3: Observational drawing: Built environment\nThe act of seeing is a dynamic and creative process. Drawing encourages us to pay attention and experience our surrounding context with a heightened \
                    and critical awareness of the visual environment and temporal qualities. Through a series of observational drawings and analytical studies produce line drawings of various media that capture form, structure, \
                    materiality, light, shadow and detail. These representations could also include context studies that record materials, layout, use, circulation and organisation.',
                    'Panel 4: Abstraction, Cut and fold\nThis is an opportunity to experiment with different line types (solid, light, heavy, thick, thin, dashed, dot dash) to create a composition which describes the selected building in an abstract way. It is also \
                    an opportunity to practice my technical drawing skills, taking care to use a variety of drafting equipment to produce straight, angular and curved lines. The panel may represent an abstracted or layered version of the building’s \
                    external form, or have different reference points such as section, plan or patterns.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1034| - Project 2 - LOCAL',
                thumbnail: 'T112.png',
                imageURLs: [
                    'U1C1/1-1-2.png', 'U1C1/1-1-22.png'
                ],
                text: [
                    '\nEach student is to research and choose a native local flora species to use as a metaphor for this project. I chose Scared Lotus as a specie from Vietnam – my hometown. \Consider the physical characteristics, how and where it grows, \
                    reproductive / generative cycle, sensory qualities and individual elements/components of the plant (for example stem, petal, bulb, root systems, bark, bud, trunk, sap, leaf, pollen, fruit). How could the plant structure - micro or macro - \
                    inform my design (cluster, pattern, scale)?\nIn conceptualising this project, the notion of metaphor is a sophisticated and refined translation. This metaphorical reference could be evident in the form, composition, texture, transformative or \
                    sensual aspects I attribute to my design.',
                    'FINAL OUTCOME:\nConcept:\n1. Using the softness of the image of a lotus branch to create a path between “space for one” and “space for two”. \n2. The roof is designed to cover the top, taking the idea of each lotus petal overlapping to protect \
                    flower buds.\n3. Between the space for one and space for two is a separate path creating a private and public space.\n4. The space for one must allow for observation in relation to Scared Lotus, observing painting and self-reflecting. \n\
                    5. The space for two must accommodate a conversation between me and my friend.  We all have the cultural background, generational and sociological implications. Therefore, the spatial device is for sitting and talking space so we can chat in a private space but connected to nature.'
                ]
            },
            {
                id: 3,
                title: '|ARCH 1034| - Project 3 - GLOBAL',
                thumbnail: 'T113.png',
                imageURLs: [
                    'U1C1/1-1-3.png'
                ],
                text: [
                    'STUDIO\n10m2 private space. The studio is designed for Stella McCartney, a space for her to design and develop inspiration. ',
                    'HUB\n30m2 public space: This space provides an area for Stella McCartney to display her work and hanging clothes for the fashion catwalk space nearby. It was considered a place of connection, passing of knowledge and preparing for fashion \
                    show. The planning and spatial dynamics should take into account the platform of delivery as a stage and how the audience receives or interacts with the performance. It must accommodate a maximum of 12 people.',
                    'EXTERNAL SPACE\nThe transition between the inside/outside experience through the idea of threshold. The connection between between STUDIO and HUB is a walking path and a courtyard around. Consider the mode of interaction and engagement, the \
                    level of intimacy or distance, the sensory qualities of the spaces in terms of light, texture, and audibility. Develop a design which responds to different layers of PRIVACY + PUBLICITY generated by specific uses and locations on the site. '
                ]
            }
        ]
    },
    {
        course_id: 'U1C2',
        title: 'Design Construction Principles',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1007| - Project 1 - Vertical Sculpture (Group Assignment)',
                thumbnail: 'T121.png',
                imageURLs: [
                ],
                text: [
                    '\nForm a group of four students from within tutorial group and construct a skeletal vertical sculpture from a single balsa sheet measuring 915mm × 100mm × 3mm (36” × 4” × 1/8”). The sculpture is to investigate and express \
                    the qualities of height, volume, form and a sense of movement. Another way of describing a \'skeletal vertical sculpture\' is \'framing model\'. The sculpture is to be made out of a single sheet of balsa with applied shapes or \
                    stuck-on off-cuts of balsa when this extra material could be used to increase the height and/or volume of my sculpture integrally, as part of its overall form.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1007| - Project 2 - Pavilion',
                thumbnail: 'T122.png',
                imageURLs: [
                    'U1C2/122-1.png', 'U1C2/122.2.jpg', 'U1C2/122.3.png', 'U1C2/122.4.png', 'U1C2/122.5.png', 'U1C2/122.6.jpg'
                ],
                text: [
                    '\nThe pavilion must explore, express and describe the combined use of the various structural and material strategies.\n\
                    -	a definitive function for my pavilion: Lookout Pavilion\n\
                    -	a definitive location: Sapa, Vietnam\n\
                    -	a definitive structure: A rigid structural Steel Frame Customized Steel and Aluminum Standoffs, RHS Column & Base Connection with 3D Folded Aluminum panels customized metal sheet, Frameless Glass Balustrade, Steel Frame Stairs, Timber Decking.\n\
                    One model will show the frame(s) of the pavilion without skin, this will most likely be a mixture of primary structure and secondary structure. The other model will be clad with whatever external skin(s) and internal lining(s): this will show \
                    what the pavilion looks like when finished, the thickness of the walls as such, the walls of this model should be slightly thicker than the other model. The scale for both models is 1:50.'
                ]
            }
        ]
    },
    {
        course_id: 'U1C3',
        title: 'Design, Culture, Environment',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1021| - Project 1 - Group Poster Presentation',
                thumbnail: 'T131.png',
                imageURLs: [
                    'U1C3/131.jpg'
                ],
                text: [
                    '\nThe assignment consists of in-class group presentations of case studies each week. My chosen case study is Altes Museum in Berlin as an example for The Renaissance \
architectural period. \nI selected case study from the period, analyse it, and summarise the period\'s core concepts and how these concepts can be demonstrated in the case \
study.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1021| - Project 2 - Essay',
                thumbnail: 'T132.png',
                imageURLs: [
                ],
                text: [
                    '\nThe 2700-word essay will be based my research in Assignment 1.',
                    'RELATIONSHIP BETWEEN THE HUMAN BODY AND THE ORDERS\nRenaissance, Neo–Classicism, Architecture, Interior \
                    Architecture \nWhat is the meaning of the human body and the Orders? What is the relationship between the human body and the Orders in the Renaissance? What are the \
                    characteristics of the Orders and how were the Orders conceived of as a system to design and compose buildings? \nPrimary Readings: Brunelleschi, Alberti, Bramante, \
                    Michelangelo. In Neo-Classical refer to Perrault; Soufflot; Wren; Adam; Palladio; and readings from the \'Renaissance\' and ‘Neo-Classicism’ reading list.'
                ]
            }
        ]
    },
    {
        course_id: 'U2C1',
        title: 'Architectural Design Studio 2',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1032| - Project 1 - Australian Residential Design Analysis ',
                thumbnail: 'T211.png',
                imageURLs: [
                    'U2C1/211.1.png', 'U2C1/211.2.png', 'U2C1/211.3.png', 'U2C1/211.4.png'
                ],
                text: [
                    '\nThis task requires to closely study a celebrated Australian Residential Design, and learn to analyse the design using selected spatial concepts. I need to \
Architecturally draft the design by hand and then undertake analysis and plan how to visually communicate that analysis of the chosen design. \n1.    Choose one of the \
celebrated Australian Residential designs: Claire Cousins- LONG HOUSE 2019 \n2.    Utilising the Architectural drawings, architecturally draft by hand the plan and \
the section at 1:100. \n3.    Analyse my selected design according to the following groupings from Making Architecture Through Being Human: A handbook of Design Ideas.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1032| - Project 2 - Home Nest (Sketch Design)',
                thumbnail: 'T212.png',
                imageURLs: [
                    'U2C1/212.1.png', 'U2C1/212.2.png', 'U2C1/212.3.png', 'U2C1/212.4.png'
                ],
                text: [
                    '\nThis requires me to consider the city scale, how we dwell among multiple others, at greater densities, what obligations we have to those we live amongst and how we \
respond to others who are not familiar to us (Gordon & Harriet will be hosting guests they have previously never met). The focus is on design intent: developing my \
conceptual and spatial ideas and translating them into architectural outcomes. \nThe task requires to demonstrate my analysis, understanding and design intent through: \n1.\
Drawing concept sketches and a detailed plan for the dwelling of the client (G & H) \n2. Locate on site the intended area and massing for the guest accommodation \n3. \
Indicate my intentions for the ‘Shared Ground’, how these dwellings will open to afford connection to one another.'
                ]
            },
            {
                id: 3,
                title: '|ARCH 1032| - Project 3 - Shared Ground',
                thumbnail: 'T213.png',
                imageURLs: [
                    'U2C1/213.1.png', 'U2C1/213.2.png'
                ],
                text: [
                    '\nThe focus for the final submission is to pursue all aspects of my design to as much depth as possible, to ensure that my design ideas and intents have been translated \
to my proposed built form in the most poetic, rigorous and innovative ways.', 'ACCOMODATION REQUIREMENTS: \nGordon & Harriet private living: Living \nDining \nKitchen \n\
Bedroom Study/workspace \nCarpark/garaging for 1 car or space for several bikes \nAmenities: Bathroom + separate toilet, storage \nShared zone of connection to afford \
sharing of living area, sharing meals and conviviality + shared laundry and drying area + outdoor areas which may include vegetable garden and a natural based play area \
for children (either their grandchildren or children of their regional guests) \nGuest Quarters private living: compact Dining + Kitchen + Living with connection to \
outside \nSleeping area to accommodate flexible numbers (up to 7 guests) \nFull Bathroom + additional toilet \nBe mindful to consider an appropriate balance of site \
occupation relative to the client occupancy \nAs a rough guide: \n• Gordon & Harriet’s permanent dwelling could be 50-60% of the built form \n• the shared zone of \
connection could be 10-20% of the site \n• the guest quarters could be 20-30% of the built form'
                ]
            }
        ]
    },
    {
        course_id: 'U2C2',
        title: 'Architectural Documentation',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1033| - Project 1 - Design Development',
                thumbnail: 'T221.png',
                imageURLs: [
                    'U2C2/221.1.jpg', 'U2C2/221.2.jpg', 'U2C2/221.3.jpg', 'U2C2/221.4.jpg', 'U2C2/221.5.jpg', 'U2C2/221.6.jpg', 'U2C2/221.7.jpg', 'U2C2/221.8.jpg', 'U2C2/221.9.jpg'
                ],
                text: [
                    'FIRST TASK\nMake deliberate choices about the materials I\'d like to use for the remainder of the project. Show this information by printing out the provided drawings and writing and drawing over the top of them to describe my material choices. In addition to these drawing mark-ups, also provide edited copies of trade literature related to these material choices (eg: manufacturers\' technical data and PDFs from manufacturers\' websites).',
                    'SECOND TASK\nTranslate the provided 2D concept design into its 3D form by constructing a physical model of it at 1:100. For this, I need to design part of the roof - show this in the model and also mark up in the elevations as a dashed line behind the parapet walls. Note the instructions provided with the drawings in order to understand more about the roof as it relates to the overall building form.',
                    'FINAL OUTCOME\n1.	BRICK: Crushed Grey (Pure Velvets) from PGH Bricks & Pavers\n2.	LIGHTWEIGHT CLADDING: Prefinished panels/Territory from Cenmintel (Residential House, External Cladding for Facades) – Woodland//Ebony'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1033| - Project 2 - Complete Documentation',
                thumbnail: 'T222.png',
                imageURLs: [
                    'U2C2/222.1.jpg', 'U2C2/222.2.jpg', 'U2C2/222.3.jpg', 'U2C2/222.4.jpg', 'U2C2/222.5.jpg', 'U2C2/222.6.jpg'
                ],
                text: [
                    '\nMy drawings with title blocks with sequential drawing numbers and a drawing register. In simple terms, my complete A3 documentation set will summarise all of my work in this course, and will consist of the following drawings, in this order:\n\
                    site plan (1:100) \n\
                    floor plans × 2 (1:50)  \n\
                    roof plan (1:50) \n\
                    elevations × 2 (1:50) \n\
                    section (1:50) \n\
                    section detail (1:10)'
                ]
            }
        ]
    },
    {
        course_id: 'U2C3',
        title: 'Architectural & Environment',
        subjects: [
            {
                id: 1,
                title: '|ARCH 1020| - Project 1 - Sun Shading Assignment',
                thumbnail: 'T231.png',
                imageURLs: [
                    'U2C3/231.1.png', 'U2C3/231.2.png', 'U2C3/231.3.png', 'U2C3/231.4.png'
                ],
                text: [
                    '\nI will undertake the solar analysis of a small residential building located in Adelaide, then use this analysis to determine how the design could be improved in relation to solar conditions, before proposing and testing design improvements. In this assessment, I will employ the climate, comfort, sun and shading skills have developed in this course.\n\
The analysis should focus primarily on the North and West facing boundaries of spaces but also consider the design of other orientations as appropriate. Consider both internal and external occupied spaces as appropriate and the ramifications of these climatic conditions in my analysis.\n\
Analyse my current residential project design using:\n\
Shaded elevations\n\
Solar masks\n\
Solar entry drawings & sections\n\
Other drawings as appropriate to my design\n\
Observe whether or not the set objectives have been met. Indicate issues I have identified and show how these can be improved. All drawings are to be developed manually. Use of computer-aided drawing software is not appropriate to this submission. Keep my workings to prove my manual calculations.',
                    '\n6 x shaded elevations (north facing) showing shadow projections at 10am, 12 noon, and 4pm (solar time) for Winter (July) and Summer (January).\n\
Shaded Plans indicating the extent of direct solar entry into the spaces at the above times and dates.\n\
Drawing (sections, plans, elevations, shading masks etc) indicating the effects of any flexible shading devices which respond to changing solar conditions over the year.\n\
Other drawings/images as appropriate to communicate the analysis I have undertaken and the design improvements I have proposed\n\
All shadow workings must be included, indicating both altitude and azimuth angles, horizontal and vertical shadow angles, and plan and section projections.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 1020| - Project 2 - Passive Design, Sound & Energy Assignment',
                thumbnail: 'T232.png',
                imageURLs: [
                    'U2C3/232.1.png', 'U2C3/232.2.png', 'U2C3/232.3.png', 'U2C3/232.4.png', 'U2C3/232.5.png', 'U2C3/232.6.png', 'U2C3/232.7.png', 'U2C3/232.8.png', 'U2C3/232.9.png'
                ],
                text: [
                    '\nFor this assessment I will continue my analysis of a residential design located in Adelaide.  I will demonstrate the ways in which the design modifies the external environmental conditions identified in assessment one, proposing and testing design improvements.\n\
This assessment comprises 3 distinct components: sound, energy and passive design. Each component of this submission will be assessed separately using the following Assessment.',
                    'Sound: \n\
Provide a discussion, utilising diagrams, of the sound transmission and room acoustics of the design. Examine noise transmission and describe how my residential project ensures external sounds are moderated and internal sound contained. Determine what external or adjacent sound sources may intrude upon the space and indicate sound paths.\n\
Indicate materials employed in the construction of the building which will play a role in the attenuation of sound.  \n\
Propose material or construction alterations which may be employed to improve acoustic control.  \n\
Indicate measures that may be taken to ensure the space is acoustically appropriate for the various activities accommodated.',
                    '\Energy:\n\
Analyse the thermal performance of the design using both steady state heat loss and glazing analysis.',
                    'Steady state heat loss:\n\
Show how my building gains and loses heat energy during the heating season\n\
use a section to show the materials employed in my building and research or calculate their U values. All materials in my initial design should comply with the Building Code of Australia BCA/NCC in relation to minimum insulation levels for the relevant climate zone.\n\
Calculate heat conduction loss rate for the residence.\n\
Estimate annual energy consumption and associated CO2 emissions.\n\
Propose alteration[s] to the design which may positively impact on the annual heating load\n\
Estimate annual savings (energy and CO2) achieved by one of these alterations.',
                    'Glazing analysis (conductance and solar heat gain of glazing):\n\
Show the glazing types used in my design, including their U values and SHGC’s.\n\
Establish whether or not the design complies with the requirements of the BCA for the relevant climate zone.\n\
Propose alteration[s] to the glazing design which may improve the thermal conditions of the building.\n\
Demonstrate the success of these proposed alterations.\n\
It is recommended that I closely follow the tutorials related to these topics.  Given the different parameters addresses by each of the methods employed, they can frequently lead to conflicting outcomes regarding design \'improvements.\' A key challenge is to balance these using my new knowledge.\n\
In completing the energy component remember that the NCC/BCA sets minimum standards. Any building which does not meet the insulation or glazing requirements cannot be built. By simply meeting the minimum standards, designing the least efficient building legally possible. As designers, we should be seeking solutions which far exceed these minimum requirements.',
                    'Passive Design:\n\
Discuss and demonstrate the passive design principles applicable to the design.\n\
Explain passive design approaches employed.\n\
Demonstrate the principles behind the approach and the range of climates to which this approach would be appropriate.\n\
Explain assumptions made regarding thermal comfort of occupants and how comfort is achieved in different seasons.\n\
Clearly define the materials employed in my building. Show their U values (see ‘energy’)\n\
Explain differing approaches used for different parts of the building as appropriate to use.\n\
Demonstrate how the design achieves the solar penetration and solar shading conditions required for the proposed approaches to work effectively. (Note: Be selective of any solar analysis undertaken, do not simply repeat submission 2)\n\
Suggest /demonstrate how the Passive Design of my proposal may be further improved.'
                ]
            }
        ]
    },
    {
        course_id: 'U3C1',
        title: 'Architectural Design Studio 3',
        subjects: [
            {
                id: 1,
                title: '|ARCH 2051| - Project 1 - Codex',
                thumbnail: 'T311.png',
                imageURLs: [
                    'U3C1/311-1.jpg', 'U3C1/311-2.jpg', 'U3C1/311-3.jpg', 'U3C1/311-4.jpg', 'U3C1/311-5.jpg', 'U3C1/311-6.jpg', 'U3C1/311-7.jpg', 'U3C1/311-8.jpg',
                    'U3C1/311-9.jpg', 'U3C1/311-10.jpg', 'U3C1/311-11.jpg', 'U3C1/311-12.jpg', 'U3C1/311-13.jpg', 'U3C1/311-14.jpg'
                ],
                text: [
                    '\nThe codex is a non-linear overview of the main elements, contexts and techniques upon which the studio will proceed. This project helped me closely read the project and site, as well as develop a series of influential ideas and strategies that will underpin my later architectural project.\n\
                    In developing this project, I observed, analysed, and documented the physical, social, cultural and political context(s) of the sites, transform abstract concepts from written texts into initial spatial ideas that will influence my design, begin to develop a set of architectural tactics to enrich the project as an imaginative entity, apply creativity techniques including Perry Kulper’s ‘Language Folds’ process to generate new ideas, and build my skills in highly speculative forms of architectural representation.\n\
                    Required drawings:\n\
                    1-	City site context ( 3 pages)\n\
                    2-	Hills site context ( 3 pages)\n\
                    3-	Literacy source ( 2 pages)\n\
                    4-	Architectural text ( 2 pages)\n\
                    5-	Language folds ( 2 pages)\n\
                    6-	Front and back cover ( 2 pages)'
                ]
            },
            {
                id: 2,
                title: '|ARCH 2051| - Project 2 - Site Moves',
                thumbnail: 'T312.png',
                imageURLs: [
                    'U3C1/312-1.jpg', 'U3C1/312-2.jpg', 'U3C1/312-3.jpg'
                ],
                text: [
                    '\nThe second project takes my diagrammatic and speculative propositions from the codex, and develops these into a series of partis diagrams to explore how these might be arranged on my site(s), and into an initial architectural proposition.\n\
                   In this project, I will explore the relationship between the prescribed program and the contexts I have identified in the codex, and critically interrogate whether the two are appropriately matched. Where dissonance is uncovered, I will adjust and augment the program for my proposition to address this.\n\
                   In the first part of this project, I will develop detailed site plans and context plans that show how the two sites connect and intersect with their contexts.\n\
                   I will then be proposing a series of four programmatic and spatial arrangements through partis diagrams. These will be annotated to show how my architectural response might contributing to, enhance, question, highlight, or challenge the existing context. Two of these should be based on spanning both sites, and two should be based on using a single site (this may be one on each site, or both on the same site). Each should demonstrate through annotation, diagramming, and overlaying drawings the translation of the ambitions, themes, and observations made in my Codex submission into architectural propositions.\n\
                   The final part of this submission is the development of initial internal and external architectural form, linked with precedent analyses. Architectural precedents should be abstracted into diagrammatic representations  and connected with my architectural approaches.\n\
                   Including:\n\
                   - Annotated and layered context plans of both sites highlighting important connections to context - Drawing scale as appropriate for site.\n\
                   - Annotated partis diagram(s) for two-site arrangement\n\
                   - Annotated partis diagram(s) for two-site arrangement \n\
                   - Annotated partis diagram(s) for single-site arrangement\n\
                   - Annotated partis diagram(s) for single-site arrangement\n\
                   - Architectural precedent analysis and proposition for internal and external form. This should be represented through:\n\
                   Precedent analysis diagrams and partis (NTS)\n\
                   Plans of all levels at 1:200\n\
                   East-west section at 1:200 including site(s) context\n\
                   North-south section at 1:200 including site(s) context'
                ]
            },
            {
                id: 3,
                title: '|ARCH 2051| - Project 3 - A Home For [?]',
                thumbnail: 'T313.png',
                imageURLs: [
                    'U3C1/313-1.jpg', 'U3C1/313-2.jpg', 'U3C1/313-3.jpg'
                ],
                text: [
                    '\nMy final submission will bring my speculative explorations together with resolved architectural details to demonstrate the translation of abstract ideas into an embodied architectural experience. This project will allow me to deepen the initial explorations of form conducted in the Site Moves project, while adding one final new element: a detailed exploration of the experience of a writer in one of my Study spaces through the construction of an inhabited 1:50 section drawing.\n\
                    The submission would bring the abstract and speculative representation and communication of context explored in the first submission together with orthodox architectural drawings. My scheme will be evaluated for its understanding of and contribution to ‘context’ at multiple scales as well as its architectural and spatial qualities. This includes pragmatic issues such as public/private accommodation, efficient space planning, circulation, economical use of site, appropriate tectonic and material complexity, and indication of structural rationale. It also includes my sensitivity and contribution to physical context, and my identification and response to social, political or cultural contextual issues. Across both the physical and non-physical contexts, my scheme should propose a significant enrichment, enhancement, or augmentation in line with the client’s ambition to establish Adelaide’s reputation globally as a distinctive place that will attract international writers and literary events.\n\
                    Including:\n\
                    - Model constructed to demonstrate architectural form, structural approach, and engagement with context. \n\
                    - Annotated site / location plan(s) that demonstrate the relationship between my project and its physical and non-physical contexts\n\
                    - A series of schematic diagrams that explain my approach to and engagement with my reading(s) of ‘context’\n\
                    Plans of all levels.\n\
                    Ground floor plan(s) should extend out into the physical context of my site(s)\n\
                    and demonstrate negotiation of thresholds and relationships.\n\
                    All plans must be accurately drawn using standard drafting techniques, but may also be augmented with creative elements as appropriate to my scheme\n\
                    - Populated section drawings (minimum of 2)\n\
                    Sections must extend beyond the external boundaries of my project\n\
                    Sections should be populated to demonstrate specific occupation patterns\n\
                    Sections must be accurately drawn using standard drafting techniques, but may \
                    also be augmented with creative elements as appropriate to my scheme\n\
                    - Populated detailed section drawing showing one of my study spaces. This section drawing should demonstrate patterns of occupation, spatial qualities, and should show an indication of structure\n\
                    - As appropriate for my scheme, such as 3D representation of the building(s) in context, 3D representation of interior space(s), and further propositional explorations. All drawings present should help to communicate the scheme, and additional drawings should not be included simply to fill empty space'
                ]
            }
        ]
    },
    {
        course_id: 'U3C2',
        title: 'Design Communication Digital',
        subjects: [
            {
                id: 1,
                title: '|ARCH 2053| - Project 1 - Modelling (Stage 1)',
                thumbnail: 'T321.png',
                imageURLs: [
                    'U3C2/321.jpg'
                ],
                text: [
                    '\nThis assignment addresses graduate qualities 1, 2, 3 and 6. This initial set out from the Site plan and Existing House is critical, as any errors at this stage, will have a compounding effect later on in the project. I will begin by modifying an existing site plan suitable for use in BIM software. I will then link the site plan, and start to model up the slab, walls, doors and windows of the existing house, and then start to model up the extension using the same process.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 2053| - Project 2 - Documentation (Stage 2)',
                thumbnail: 'T322.png',
                imageURLs: [
                    'U3C2/322-1.jpg', 'U3C2/322-2.jpg', 'U3C2/322-3.jpg', 'U3C2/322-4.jpg', 'U3C2/322-5.png', 'U3C2/322-6.png', 'U3C2/322-7.png', 'U3C2/322-8.png',
                    'U3C2/322-9.jpg', 'U3C2/322-10.jpg', 'U3C2/322-11.jpg', 'U3C2/322-12.jpg', 'U3C2/322-13.jpg', 'U3C2/322-14.jpg', 'U3C2/322-15.jpg', 'U3C2/322-16.jpg', 'U3C2/322-17.jpg'
                ],
                text: [
                    '\nThis assessment addresses Graduate Qualities 1, 3 and 4. The final portion of the assessment continues on from the previous submission. I will continue to finalise the model and correct any issues outlined in the assessment feedback. Once corrected I will then add the roof, eaves, fascia and gutters and then I can then generate the required views for my submission. Adding to this will be a sun shade diagram for the winter solstice for 9 am and 3 pm. Some views will automatically be generated, so I can modify those to suit. I will then add annotations and details elements to each of the views to finalise our documentation set.'
                ]
            }
        ]
    },
    {
        course_id: 'U3C3',
        title: 'Architecture & Modernity ',
        subjects: [
            {
                id: 1,
                title: '|ARCH 3053| - Project 1 - Seminar Essay',
                thumbnail: 'T331.png',
                imageURLs: [
                ],
                text: [
                    '\nReference: ‘The Problematic of Architecture and Utopia’ /Nathaniel Coleman /Utopian Studies \
                    Vol. 25, No. 1, SPECIAL ISSUE: UTOPIA AND ARCHITECTURE (2014), pp. 1-22 \n\
                    Read Coleman’s analysis of the interrelationship between modern architecture and utopian thinking/studies. Paying attention to his distinction between ‘utopian’ and ‘visionary’ projects, analyse one building or project (built or unbuilt) created between 1800 and 1970 and discuss how it reflects or challenges Coleman’s thesis.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 3053| - Project 2 - Final Essay',
                thumbnail: 'T332.png',
                imageURLs: [
                ],
                text: [
                    'Essay Question:\n\
                    ‘I take modernism to be a cultural movement which crystallized sometime between 1910 and 1930 as a response to (rather than as only an expression of) the process of modernization that sought to rationalize and thereby demystify the world, destroy superstition and religion, demythify politics, and make the world safe for capitalism. I stress that, for me, cultural modernism is a response to modernization – positive or negative, as the case may be – in the domain of the symbolic, in which the aim is to set up an alternative ‘imaginary relation to the real conditions of existence’ (Althusser) to those prevailing during the nineteenth century in European society. In this sense, modernism in general can be seen as a vision of reality which presumes the necessity of a radical revision of what is meant by ‘history.’\n\
                    Hayden White, ‘Modernism and the Sense of History’, in Journal of Art Historiography,Glasgow, 15,  (Dec 2016): 1-15.\n\
                    Using White’s statement as a prompt, discuss how two related examples of architecture express an idea of a ‘radical revision of what is meant by history’. How do they both confirm and deny their historical moment? It is important to investigate the nature of the relation between the two works, either by, for example, direct influence, similarity of ambition, ultimate effect or directly opposite ambitions.'
                ]
            }
        ]
    },
    {
        course_id: 'U4C1',
        title: 'Architectural Design Studio 4',
        subjects: [
            {
                id: 1,
                title: '|ARCH 2052| - Project 1 - FASCINANS',
                thumbnail: 'T411.png',
                imageURLs: [
                    'U4C1/411-1.jpg', 'U4C1/411-2.jpg', 'U4C1/411-3.jpg', 'U4C1/411-4.jpg', 'U4C1/411-5.jpg', 'U4C1/411-6.jpg', 'U4C1/411-7.jpg', 'U4C1/411-8.jpg',
                    'U4C1/411-9.jpg', 'U4C1/411-10.jpg', 'U4C1/411-11.jpg', 'U4C1/411-12.jpg', 'U4C1/411-13.jpg', 'U4C1/411-14.jpg'
                ],
                text: [
                    'Panel 1. \n\
                    Place mappings. Images of place, perception, texture, light, sound, space, atmosphere. Include sketches, photos, images, materials, limited text. All should be printed monolithically and not stuck on.',
                    'Panel 2. \n\
                    Conceptual moves. 4 x alternative 1:500 conceptual mass plans, responses to the outline brief. Sketches, images, suggesting context. Sketch sections 1:500 of conceptual space, interior shape.',
                    'Report 1. \n\
                    Matrix brief. 4 x A4 pages of diagrams with supporting labelling and text, clearly annotated, repre- senting daily, weekly, monthly, yearly activities, adjacency relationships, and the functional and ceremonial priorities of the different beliefs and faiths examined. Refer to F. Ching’s ‘Diagramming’ (see Reading List online). Present as an A4 bound report with cover page.',
                    'Maquettes. \n\
                    4 x alternative 1:500 conceptual mass and void models, representing space within form and place outside of form. Each on a core boxboard base to site dimensions.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 2052| - Project 2 - TREMENDUM',
                thumbnail: 'T412.png',
                imageURLs: [
                    'U4C1/412-1.png', 'U4C1/412-2.png', 'U4C1/412-3.png'
                ],
                text: [
                    'Drawings 1. \n\
                    Manipulate conceptual mass models to examine intersection, union, and subtrac- tion of forms, hollowing-out and creating internal space, external space and openings. Focus on the significant space(s) for the Multi Belief Place (not all spaces). Consider about making spaces that will be occupied and used for ceremony, dance, storytelling, worship and prayer. Create 10 alternative manipulations of these spaces and choose 4 for presentation. Capture images that best represent my conceptual narrative and in- dicate plan and iso or axonometric (not perspective). Refer to Assignment 2 Drawing 1 Template for guidance.',
                    'Drawings 2. \n\
                    Based on the previous exercise and with online tutorials as a guide, make long or short section cuts at through the 4 manipulations. Sections should indicate enclosure thickness to imply structure and materiality. Rather than using identifiable elements and components such as windows and doors, keep openings simple, but make clear the difference between inside and outside. ',
                    'Spatial & Sectional Models. \n\
                    Make a core boxboard base for a 1:500 spatial model, and a 1:200 sectional model of my significant space(s). Build contours with corrugated cardboard. Watch, ‘Making a Site Model Making’ by 30 x 40 Design Workshop (‘Links/learnonline’). For inspiration use a manipulation made for Drawing 1, and a section from Drawing 2. Define space and place with framework materials, hollowing-out, and assembling form. Use materials noted, but no solid forms. Colours: monochrome, browns/greys. Leave materials open to interpretation. Refer to Assignment 2 Model Base Templates for guidance.'
                ]
            },
            {
                id: 3,
                title: '|ARCH 2052| - Project 3 - MYSTERIUM',
                thumbnail: 'T413.png',
                imageURLs: [
                    'U4C1/413-1.png', 'U4C1/413-2.png', 'U4C1/413-3.png'
                ],
                text: [
                    'Drawings 1. \n\
                    A1 portrait. To include the following.\n\
                    Partial and/or whole views, sketches and images. As with models, leave materials open to interpretation. Con- sider expression of texture and the use of a limited colour palette. Consider the use of axonometric, isometric and orthogonal projections to easily represent 3 dimensional views. Consider freehand sketches of significant spaces and places in my Multi Belief Place and represent their mood and atmosphere. Provide text explaining my conceptual narrative in 100 words (or fewer, plus labelled diagrams), clearly printed.',
                    'Drawings 2. \n\
                    A1 portrait. To include the following.\n\
                    Plan of whole space and place 1:500 (Location), 1:200 (Site & Ground Plan), 1:200 (Upper levels). Represent all ceremonial, functional, internal, in-between and external space. Long (e.g. 2 x A1 side-by-side with Drawing 1) and short sections 1:200.',
                    'Sectional or Whole Model. \n\
                    For both types of model, make a base using 1.5mm core box board, add contours with 2mm cork or other appropriately scaled material. '
                ]
            }
        ]
    },
    {
        course_id: 'U4C2',
        title: 'Advanced Design Media',
        subjects: [
            {
                id: 1,
                title: '|ARCH 3054| - Project 1 - Online Tutorial (ArchiStar)',
                thumbnail: 'T421.png',
                imageURLs: [
                    'U4C2/421-1.jpg', 'U4C2/421-2.jpg', 'U4C2/421-3.jpg', 'U4C2/421-4.jpg', 'U4C2/421-5.jpg'
                ],
                text: [
                    '\nYou are required to complete the 5 (five) modules through Archistar Academy:\n\
                    Advanced VR in Unreal Engine 4 \n\
                    Atmospheric Architectural Drawing \n\
                    Photo realistic Interior Rendering \n\
                    Rendering Commercial Exterior (Night) \n\
                    Adobe InDesign CC'
                ]
            },
            {
                id: 2,
                title: '|ARCH 3054| - Project 2 - Walkley House Portfolio',
                thumbnail: 'T422.png',
                imageURLs: [
                    'U4C2/422-1.jpg', 'U4C2/422-2.jpg', 'U4C2/422-3.jpg', 'U4C2/422-4.jpg', 'U4C2/422-5.jpg', 'U4C2/422-6.jpg', 'U4C2/422-7.jpg', 'U4C2/422-8.jpg',
                    'U4C2/422-9.jpg', 'U4C2/422-10.jpg', 'U4C2/422-11.jpg', 'U4C2/422-12.jpg', 'U4C2/422-13.jpg', 'U4C2/422-14.jpg', 'U4C2/422-15.jpg', 'U4C2/422-16.jpg', 'U4C2/422-17.jpg', 'U4C2/422-18.jpg'
                ],
                text: [
                    '\n1. Research and investigate the Walkley House.\n\
2. Model the Walkley House in my chosen CAD software\n\
3. Render the Walkley House in my chosen animation software\n\
4. Present the story/narrative of the Walkley House as an A5 hybrid multi-media booklet portfolio\n\
Now, imagine that I am Robin Boyd - the designer of the Walkley House - and that you have an online portfolio submission due for the project. Treat the composition of the booklet as if the Walkley House was my own and you were attempting to communicate its various concepts, themes, and spatial quality.\n\
The material to the right, will be collated into a booklet of 12 (twelve) pages minimum covering the techniques and outcomes of the course. The booklet will form the principal output format for the course and should be submitted through Learnonline as an interactive .pdf file.\n\
The booklet will include a short 300 word (max) reflective summary that describes the design approach you have employed, and use of specific digital media and the architectural design content you are engaging with. This text is expected to be an academically critical work, incorporated as part of our portfolio, that should demonstrate an understanding of the relationship of representation methods to media in architectural design.\n\
The booklet will include all necessary titles and annotations that would be conventionally included in a portfolio of an architectural project.\n\
Including:\n\
-2 (two) Interior and 2 (two) Exterior perspective renders (not to scale)\n\
Still images of fully rendered scenes of the interiors and exteriors of the Walkley House. 4 (four) images minimum, preferably showing differing lighting conditions (day and night, for example).\n\
-1 (one) render of each Walkley Houses floor plans (1:100 or 1:50)\n\
Rendered image of the plan taken from my modelled environments and adjusted using the techniques covered in the Archistar Academy modules.\n\
-VR experience or immersive packaged game:\n\
VR experience or immersive first person game focusing of three (3) key spaces of the Walkley House; an outdoor space, a living space, and an additional space of my choice. Use strategic lighting, detail, and other game design strategies to direct the player between these key spaces where you have invested the most time. These will be played using a PC or VR headset (if available).'
                ]
            }
        ]
    },
    {
        course_id: 'U4C3',
        title: 'Design Construction Multistorey ',
        subjects: [
            {
                id: 1,
                title: '|BUIL 2006| - Project 1 - Precedents & Design',
                thumbnail: 'T431.png',
                imageURLs: [
                    'U4C3/431-1.jpg', 'U4C3/431-2.jpg', 'U4C3/431-3.jpg', 'U4C3/431-4.jpg', 'U4C3/431-5.jpg'
                ],
                text: [
                    '\nEach group will undertake three studies (one case study per group member). The allocation of each case study to individual team members will be determined by the group.\n\
                    • One contemporary (ie less than 10 years old) multistorey building of my choice. (my chosen case study\n\
                    The contemporary and historic multistorey buildings may be local or international, and need to be of similar scale to undertake a comparative analysis. It must be a minimum of 6 storeys tall and of a similar type to enable a useful comparison. Analyse the structure, vertical circulation and service systems of my three multistorey buildings and present my findings to my tutorial group.',
                    'PART A:\n\
                    Sheet 1: \n\
                    Building information\n\
                    • Relevant information such as building name, location, architects, etc.\n\
                    • At least one clear photograph\n\
                    • Drawings at scale\n\
                    • Identify the structural typology (e.g. rigid frame, tube etc. as discussed in the lectures)\n\
                    • Identify the core typology (e.g. central, split, end, atrium as discussed in lectures)\n\
                    • My analysis should make reference to the relationship between the approaches to structure / circulation and issues such as the effect on the floorplate design including access to light, views, sub-divisibility, expression or concealment of structure, effect on facades.\n\
                    • Bibliography / referencing: all material must be cited in accordance with Harvard or Chicago Style, including web-based material.\n\
                    Sheet 2: \n\
                    Analysis diagrams\n\
                    • Plan, Section and/or isometric diagrams which describe my analysis. Each diagram should be titled but otherwise text shall not be included. Separate diagrams should include:\n\
                    • Structural grid (do not include any building elements)\n\
                    • Structural elements carrying vertical loads\n\
                    • Vertical load paths including any major transfer structure\n\
                    • Lateral stability including bracing or shear walls\n\
                    • Columns size / material / spacing\n\
                    • Floor structure including beams\n\
                    • Vertical transportation (lifts, service lifts, escalators)\n\
                    • Vertical access and egress (fire stairs and other stairs)\n\
                    • Access (entry) and egress (discharge) paths to open space at ground level\n\
                    • Identify any atria or voids that penetrate floors\n\
                    • Identify major service areas (plantrooms) in the building',
                    'PART B:\n\
                    Design will build on our precedent analysis by undertaking an analysis of floor-plate and structural options for a multistorey building and develop a design concept. \n\
                    Analysis\n\
                    Each group will explore and evaluate a multistorey structural system and develop a design solution to the brief and site constraints. Selection of the structural system will occur in consultation with our tutor. Evaluation of alternatives is an integral part of the design process and assessment criteria for this assessment. Our analysis should include, but not be limited to, the following considerations:\n\
                    • Floor plan: consider provision of open office space suitable for different tenants (sub-divisibility); access to natural light and views, ventilation; quality of space\n\
                    • Circulation: configuration and position of lift core(s) + fire stairs\n\
                    • Structure: structural system including lateral stability (bracing / shear walls); column grid, floor + roof\n\
                    • Services: provision + location of vertical services risers and major service areas\n\
                    • NCCA/BCA: provision + location of fire stairs, travel distances, egress to street level; calculation of sanitary requirements + provision of toilets\n\
                    Plans: \n\
                    -1:200 scale plans for ground floor and first floor\n\
                    -1:500 scale plans for all other floors. Note that if plans are common to a number of floors they only need to be shown once and labelled ‘typical’ (eg Typical Floor Plan : Levels 5 to 10)\n\
                    Sections: 1:200 sections (longitudinal + cross section)\n\
                    3D Drawings: three dimensional drawing/s (axonometric/isometric or similar) illustrating the structural strategy for my building. You may choose how this is graphically illustrated – it may be a series of individual 3D drawings of different components, an ‘exploded’ axonometric/isometric, or a single drawing with separate elements highlighted.’'
                ]
            },
            {
                id: 2,
                title: '|BUIL 2006| - Project 2 - Detailed Design',
                thumbnail: 'T432.png',
                imageURLs: [
                    'U4C3/432-1.jpg', 'U4C3/432-2.jpg'
                ],
                text: [
                    'Project brief:\n\
                    Vision\n\
                    A contemporary building designed for UniSA that provides a healthy, interactive and enjoyable environment for its users and the public. The building should accommodate a number of tenants and provide for both secure defined tenancies as well as provide opportunity for informal interaction and collaboration, through breakout areas, landscaped terraces, atria, open stairs or similar. The office accommodation component should be an open, flexible space with good natural light and views. The project shall be of exemplar design, contextually appropriate, and address environmental issues through its design.\n\
                    Site Details\n\
                    Site: UniSA David Pank Building DP (building to be demolished)\n\
                    Location: 160 Currie Street, Adelaide (bounded by Currie Street to the south, Clarendon Street to the west, Philip Street to the north and TAFE SA to the east)\n\
                    Site dimensions – approximate dimensions (to be used for design) 26m x 60m (1560m2)\n\
                    Assume the site is flat (ie no change of grade) for the purpose of this project.\n\
                    Accommodation Schedule\n\
                    Architecture Museum\n\
                    Relocated from the Kaurna Building and expanded to meet growing material archive and expand public interaction.\n\
                    Archive collection/400m2 (Security, including fire-proofing and light sensitive documents. Staff access only)\n\
                    Research area /100m2 (To view retrieved archive documents and microfiche or online and to undertake research)\n\
                    Exhibition space/250m2 (Including models, drawings, books, audio visual)\n\
                    Reference Library/100m2 (Public access)\n\
                    Reception/10m2 (May co-locate with Administration)\n\
                    Administration,office area /40m2 (Incorporating kitchenette)\n\
                    General storage/40m2 (Adjacent to Administration)\n\
                    Workroom /60m2 (Layout area for processing documents, scanning, photography, printing and preparation of material for storage)\n\
                    Toilets (Shared public and staff, including accessible facilities in accordance with the BCA)\n\
                    Total : 1000m2 (Not including toilets, services and circulation)\n\
                    Office accommodation\n\
                    To accommodate UniSA staff and industry partners. Fitout to be determined at a later date – provide base building only.\n\
                    14,000m2 Gross Floor Area (GFA).\n\
                    Floor‐plate minimum 800m2, maximum 1250m2 capable of subdivision into approximately 250-500m2 areas.\n\
                    Generally open plan office areas.\n\
                    Other considerations\n\
                    Minimum 2700mm ceiling heights, typical, with 4000mm (minimum) at ground floor.\n\
                    Consider provision of balconies, terrace, or atrium.\n\
                    Good solar control to limit heat gain and glare.\n\
                    Natural light to lift lobbies and other circulation areas where possible.\n\
                    No carparks required.\n\
                    Consider adjacent building and potential future developments on surrounding sites.\n\
                    The relationship of structure, construction and servicing systems to the building perimeter is complex and is often informed by the designer’s intentions regarding both the interior conditions and façade design. Assessment 2 focuses on this relationship through a detailed study of the perimeter condition of my multi-storey building.\n\
                    Using my building from Assessment 1, I am to undertake a detailed design of selected portions of the building façade. I am to focus on the base of the building (ground floor + first floor including a canopy structure) and the top of the building (top floor including roof). \n\
                    Façade requirements\n\
                    You are to develop one façade of my building and include the following:\n\
                    - Appropriate external sun-shading\n\
                    - Glazing plus cladding with the solid portions of the façade complying with the BCA thermal performance requirements. This will be discussed in the tutorial sessions\n\
                    - Internal blinds\n\
                    - Ceilings with voids\n\
                    - Dimensions of ceiling and floor to floor height, e.g. 2700mm ceiling height (with minimum 3500 floor to floor height)\n\
                    - Floor finish\n\
                    My detailed design is to be presented with the following:\n\
                    Design Diagrams: describing my design strategies\n\
                    Elevation (whole of building): 1:200 elevation (one facade)\n\
                    Facade Section: 1:100 section of my external façade (full building height) extending 3-4 metres into my building. Use this section to cross reference to my Façade study and Section details.\n\
                    Façade Study: 1:50 including part plan, section + elevation selected to illustrate construction and the junctions of different materials. See details below.\n\
                    Section Details: 2 x 1:10 section details (one of external wall/roof junction)\n\
                    Notes/legend: provide precise annotation specifying primary materials and finishes.'
                ]
            }
        ]
    },
    // {
    //     course_id: 'U5C1',
    //     title: 'Architectural Design Studio 6',
    //     subjects: [
    //         {id: 1,
    //         title: '|ARCH 1032| - Project 1 - Australian Residential Design Analysis ',
    //         thumbnail: 'T321.png',
    //         imageURLs: [
    //             'U2C1/211-1.png', 'U2C1/211-2.png', 'U2C1/211-3.png', 'U2C1/211.4.png'
    //         ]},
    //         {id: 2,
    //         title: '|ARCH 1032| - Project 2 - Home Nest (Sketch Design)',
    //         thumbnail: 'T322.png',
    //         imageURLs: [
    //             'U2C1/212-1.png', 'U2C1/212-2.png', 'U2C1/212-3.png', 'U2C1/212.4.png'
    //         ]},
    //         {id: 3,
    //         title: '|ARCH 1034| - Project 3 - Shared Ground',
    //         thumbnail: 'T313.png',
    //         imageURLs: [
    //             'U2C1/223-1.png' , 'U2C1/213-2.png' 
    //         ]}
    //     ]
    // },
    // {
    //     course_id: 'U5C2',
    //     title: 'Architecture & Ecology',
    //     subjects: [
    //         {id: 1,
    //         title: '|ARCH 1032| - Project 1 - Australian Residential Design Analysis ',
    //         thumbnail: 'T321.png',
    //         imageURLs: [
    //             'U2C1/211-1.png', 'U2C1/211-2.png', 'U2C1/211-3.png', 'U2C1/211.4.png'
    //         ]},
    //         {id: 2,
    //         title: '|ARCH 1032| - Project 2 - Home Nest (Sketch Design)',
    //         thumbnail: 'T322.png',
    //         imageURLs: [
    //             'U2C1/212-1.png', 'U2C1/212-2.png', 'U2C1/212-3.png', 'U2C1/212.4.png'
    //         ]},
    //         {id: 3,
    //         title: '|ARCH 1034| - Project 3 - Shared Ground',
    //         thumbnail: 'T313.png',
    //         imageURLs: [
    //             'U2C1/223-1.png' , 'U2C1/213-2.png' 
    //         ]}
    //     ]
    // },
    // {
    //     course_id: 'U5C3',
    //     title: 'Contemporary Design Theory',
    //     subjects: [
    //         {id: 1,
    //         title: '|ARCH 1032| - Project 1 - Australian Residential Design Analysis ',
    //         thumbnail: 'T321.png',
    //         imageURLs: [
    //             'U2C1/211-1.png', 'U2C1/211-2.png', 'U2C1/211-3.png', 'U2C1/211.4.png'
    //         ]},
    //         {id: 2,
    //         title: '|ARCH 1032| - Project 2 - Home Nest (Sketch Design)',
    //         thumbnail: 'T322.png',
    //         imageURLs: [
    //             'U2C1/212-1.png', 'U2C1/212-2.png', 'U2C1/212-3.png', 'U2C1/212.4.png'
    //         ]},
    //         {id: 3,
    //         title: '|ARCH 1034| - Project 3 - Shared Ground',
    //         thumbnail: 'T313.png',
    //         imageURLs: [
    //             'U2C1/223-1.png' , 'U2C1/213-2.png' 
    //         ]}
    //     ]
    // },
    {
        course_id: 'U6C1',
        title: 'Architectural Design Studio 6',
        subjects: [
            {
                id: 1,
                title: '|ARCH 3050| - Project 1 - Schematic Design of Hart Island Hope Memorial',
                thumbnail: 'T611.png',
                imageURLs: [
                    'U6C1/611-1.jpg', 'U6C1/611-2.jpg', 'U6C1/611-3.jpg', 'U6C1/611-5.jpg', 'U6C1/611-6.jpg'
                ],
                text: [
                    'PART 1:\n\
                    In my group, research and analyse 8 (eight) minimum contemporary memorials (built after 2000) as precedent for the projects this semester. Each precedent analysis should include the following:\n\
                    -Image of the project’s exterior and interior\n\
                    -Ground plan\n\
                    -Section\n\
                    -Any supporting analytical diagrams that communicate\n\
                         the conceptual intent of the project drawn by its\n\
                         designer\n\
                    -300 word text-based precedent description and\n\
                    analysis including key facts about the project and 3 (three) key concepts, listed as bullet points, that define the primary design intent of the project\n\
                    -On the top-left of the sheet, list the following:\n\
                    -Name of the Artist/Architect/landscape Architect o Name of the Artwork/Building/Landscape\n\
                    -Dates of design-to-construction\n\
                    -Location City, Country\n\
                    -Reference the source/s of these images in 10pt font at the bottom of the page',
                    'PART 2: RESEARCH AND ANALYSIS ABOUT BURIAL: \n\
                    In what ways are death and the human body addressed conceptually and programmatically as built and natural environments?\n\
                    This stage of the project aims to enable to develop an understanding of the various aspects associated with death and the human body, both conceptually and pragmatically. It will assist my in developing knowledge about the various processes and programmatic elements associated with how bodies are humanised and de-humanised after death through burial.\n\
                    Following are some concepts and themes to get you started:\n\
                    -How do we conceptually relate to the human body of the deceased?\n\
                    -How do we pragmatically treat the human body of the deceased?\n\
                    -How do we pragmatically store the human body of the deceased?\n\
                    -How do we conceptually and pragmatically bury the human body of the deceased?',
                    'PART 3: DEVELOPING A SCHEMATIC DESIGN OF THE HART ISLAND ‘HOPE’ MEMORIAL\n\
                     Through research and analysis into the cultural history of hart island, mapping of conceptual and pragmatic attractors, and identifying who and what might be memorialised and why.\n\
                    In the previous stages of the project, students have developed knowledge concerning how we conceptually and pragmatically understand memorial environments and burial processes related to the human body. Now it needs to apply design mapping strategies to draw out the complex range of historical and conceptual parameters as ‘trajectories’ that will assist us in generating a spatial organisation for the island as a living memorial. In order to give a physical presence to these trajectories, cartography is proposed as an operative drawing medium through which a variety of internal and external forces acting on the site can be explored and represented. In so doing, the cartographic map allows for conscious and deliberate design investigation to take place as a medium of analysis and representation that you will interpose with other modes of analysis, representation, and projection, such as collage.\n\
                    Including:\n\
                    -SPECULATIVE ‘SPATIAL OPERATIONS’ DRAWING Scale: @1:2500 ~A1\n\
                    Apply ‘spatial operations’ to the site, akin to Perry Kulper’s work, or the various iterations of \n\
                    Sectionally transect the site from edge to edge along identified force paths. Combine and enfold a number of these paths and explore the potential interconnections between these slices. Focus my design interventions only along the slices in order to connect across the island.\n\
                    - MODEL OF FOLLIE DEVIATIONS Scale: @1:200\n\
                    Develop models that explore the architectural language of deviation between each follie and the built and natural characteristics of the site.\n\
                    Consider siting on, over, or buried within/under the site. Consider the surrounding water, including design opportunities from tidal flow in changing the topography, architectural form, program, and spatial experience throughout the day and year.\n\
                    Explore how to curate the co-location of the follie deviations models on/over/adjacent to the speculation spatial operations drawing to indicate the locations they are intended to be sited. '
                ]
            },
            {
                id: 2,
                title: '|ARCH 3050| - Project 2 - Detailed Design of Hart Island Hope Memorial',
                thumbnail: 'T612.png',
                imageURLs: [
                    'U6C1/612.jpg'
                ],
                text: [
                    'BRIEF:\n\
                    There are three separate, but inter-related program elements that each Hart Island ‘Hope’ Memorial project must integrate and co-develop in their proposal as outlined below.\n\
                    Individually adopt, adapt, and expand a number of the follies designed as a group in Assignment 1 incorporating and responding to the following accommodation program:\n\
                    1. A Mortuary – to store and prepare the deceased for burial\n\
                    2. A Machine – to store and maintain the machinery used for burial and the island’s ongoing operation\n\
                    3. A Memory Mausoleum – a series of spaces in which to reflect and memorialise the deceased buried on Hart Island; including\n\
                    A. specific memory space designed to support the memorialisation of Ellen Torron, and\n\
                    B. collective memory space designed to support the memorialization of everyone buried on Hart Island.',
                    '\n1. Mortuary (574m2)\n\
                    Garage (95m2) \n\
                    -Cold store deceased transport van/70m2\n\
                    -Storage/ 15m2\n\
                    -Ante room/10m2\n\
                    Burial preparation (135m2)\n\
                    -Prep rooms/ 15m2 \n\
                    -Deceased cold storage for 200 coffins (25 per day for one week)\n\
                    Coffin (250m2) \n\
                    -Coffin construction workshop/ 100m2\n\
                    -Coffin construction material storage/ 50m2\n\
                    -Coffin storage for 100 coffins/ 100m2 \n\
                    Administration (70m2)\n\
                    -Administration offices/25m2 \n\
                    -Kitchen/ 15m2 \n\
                    -Lunch room/25m2 \n\
                    -Storage/ 5m2\n\
                    -Plant room/ 20m2\n\
                    Toilets (45m2)\n\
                    -Male toilets/ 10m2 (1x wc + urinal + 2x basin)\n\
                    -Female toilets /10m2  (2x wcs + 2x basin)\n\
                    -Unisex accessibility toilet/5m2 (1x wc + basin)\n\
                    -Unisex showers (4x showers) + storage lockers/ 20m2',
                    '\n2. Machine\n\
                    Backhoe Loader (CAT 428, or similar)/ 100 m2\n\
                    Tipper truck (Kenworth T409SAR, or similar)/ 100m2 \n\
                    ATV can-am Commander DPS, or similar/ 30m2\n\
                    Machinery maintenance workshop/ 50m2 \n\
                    Unisex accessibility toilet (2x wc + urinal + 2x basin)/ 15m2 \n\
                    Unisex showers (2x showers) + storage lockers/ 10m2',
                    '\n3. Memory Mausoleum\n\
                    (A) Contemplation and reflective memorial space for the unborn\n\
                    (B) Contemplation and reflective memorial space/s for everyone buried on the hart island ‘hope’ memorial\n\
                    \n\
                    Including:',
                    '\n1. SITEPLAN@1:2500~A1\n\
                    Drawing should be completed individually and include:\n\
                    -Topography on which my proposition is sited on, over, or buried within my site.\n\
                    -Surrounding water (consider design opportunities from tidal flow in changing the topography throughout the day).\n\
                    -Follies you have adapted with program in Assignment 2 together with remaining follies designed in my groups in Assignment 1 including existing buildings and their urban apron. Indicate existing built form and building massing in white, with my scheme in a contrasting tone/colours.',
                    '\n2. MODEL@1:100\n\
                    Model should include:\n\
                    -Model of 1of the 3 program elements for my scheme (mortuary, machine, memory museoleum).\n\
                    -Indication of material and textual intentions to communicate the formal and compositional intention of my scheme, whilst also indicating its relation to the adjacent architectural and landscape context on the island.\n\
                    -Any immediate adjacent existing forms in white.',
                    '\n3. LOCATIONPLAN@A5\n\
                    Drawing should be completed individually and include:\n\
                    -Hart Island located in relation to surrounding smaller islands, and Manhattan, New Jersey, Bronx, Staten Island, Queens, and Brooklyn.\n\
                    -Indicate ferry travel paths from other local islands to Hart Island and from the mainland, such as Whitehall Ferry Terminal on the southern tip of Manhattan to Hart Island.\n\
                    -Indicate North Point and important adjacent locations.',
                    '\n4. GROUNDPLAN@1:100~A1 Plan should include:\n\
                    -Indicatation of room program and furniture arrangement that corresponds to the room’s function. All fixed fitting and loose furniture should be indicated in plan.\n\
                    -Describe the urban apron and the topography on which my scheme sits or is buried within, including landscape and outdoor spaces.\n\
                    -Drawn people in plan to scale rooms and gatherings of people in indoor and outdoor spaces.\n\
                    -Don’t forget the basic drawing conventions: All stairs and door swings should be indicated. Any overhangs of roofs, balconies, or skylights should be indicated as dotted lines.',
                    '\n5. ARCHI-URBANSECTION@1:100 Section should include:\n\
                    -All buildings on my site sufficient to illustrate sectional aspects of compositional form and spatial hierarchy, site context, and major program elements of my scheme.\n\
                    -All fixed fitting and loose furniture.\n\
                    -Structure. No flat roofs. Give walls, floors, and roofs\n\
                    varying thicknesses to accommodate structure and services and spatial and experiential variety. For example, allow minimum 300mm–900 for ceiling structure and servicing. Interior ceiling surface geometry should be different from exterior roof surface geometry.',
                    '\n6. 3-DIMENSIONALGRAPHICREPRESENTATION(s)NTS 3D graphic representations should:\n\
                    -Either be in the form of axonometric, perspective, or montaged model photographs.\n\
                    -Included at least one interior drawing per Mortuary, Machine, and Memory Mausoleum, illustrating its spatial and atmospheric character in context.\n\
                    -Include at least one drawing describing a significant ‘occupied’ exterior space on the island.\n\
                    -Ground all 3d drawings in their island landscape context. No drawings of buildings floating in space without context. In all cases, my drawings should demonstrate the life of the architecture in its everyday use (which includes people, landscape, eating, talking, ... people going about their everyday life in my scheme). Illustrate the functions of architectural program by the way in which you illustrate people using the spaces.'
                ]
            }
        ]
    },
    {
        course_id: 'U6C2',
        title: 'Architecture & Ecology',
        subjects: [
            {
                id: 1,
                title: '|ARCH 3028| - Project 1 - Environmental Medelling Analysis',
                thumbnail: 'T621.png',
                imageURLs: [
                    'U6C2/621-1.png', 'U6C2/621-2.png', 'U6C2/621-3.png', 'U6C2/621-4.png', 'U6C2/621-5.png', 'U6C2/621-6.png', 'U6C2/621-7.png', 'U6C2/621-8.png',
                    'U6C2/621-9.png', 'U6C2/621-10.png', 'U6C2/621-11.png', 'U6C2/621-12.png', 'U6C2/621-13.png', 'U6C2/621-14.png', 'U6C2/621-15.png', 'U6C2/621-16.png', 'U6C2/621-17.png', 'U6C2/621-18.png',
                    'U6C2/621-19.png', 'U6C2/621-20.png', 'U6C2/621-21.png', 'U6C2/621-22.png', 'U6C2/621-23.png', 'U6C2/621-24.png'
                ],
                text: [
                    'Project aim:\n\
                    The aim of this assignment is to develop basic skills in the use environmental analysis software as both an evaluation tool and a generative tool in my architectural process. The assignment will build my capacity to integrate environmental modelling approaches into my design process. The assessment is focused on my ability to:\n\
                    - work in a team-based environment\n\
                    - effectively (critically) analyse outputs\n\
                    - identify design decisions affecting building performance\n\
                    - identify opportunities for action beyond those that are possible through the software (for example, sustainability objectives I have learned about in the other parts of the course), and\n\
                    - effectively communicate my analysis.\n\
                    Project requirements:\n\
                    1. The baseline analysis should be completed assuming the building is constructed to the current minimum BCA standards.\n\
                    2. Modification 1 should assume the overall floorplan is set and focus on identifying changes in materials (including researching and documenting evidence for their thermal performance characteristics in my report), façade composition, and external shading devices that can improve building performance. Evidence (specification) is required for all material improvements beyond the BCA minimum standards utilised in the baseline analysis.\n\
                    3. Modification 2 should assume I am conducting a design-review at a stage of the project where significant changes to the floor plan are possible. While I should endeavour to maintain the overall density of the program, it is possible to reduce this if my design achieves a commensurate increase in spatial quality.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 3028| - Project 2 - Research Report',
                thumbnail: 'T622.png',
                imageURLs: [
                ],
                text: [
                    ' Assessment Aim:\n\
                    The aim of this assessment is to develop my understanding of sustainability concepts and how they might apply to architectural practice. My research report provides an opportunity to explore the application of the major concepts from the course in a real-world project or set of projects. In particular, it provides the opportunity to compare the ambitions set out in academic research with the realities of built projects to develop a more nuanced view of how sustainability principles are applied in practice.\n\
                    Research Report structure:\n\
                    - Introduction\n\
                    - Background\n\
                    - Method\n\
                    - Case Study Project\n\
                    - Results\n\
                    - Discussion\n\
                    - Conclusion',
                    'Questions: \n\
                    In 1943 Winston Churchill famously stated that ‘We shape our buildings, and afterwards our buildings shape us’. Discuss this quote with reference to the health consequences of our built environment, and at least one case study project that focused specifically on health outcomes.\n\
                    Optional Extension: Consider the role of remote work in changing the way we conceive of our cities and the design of our office buildings.'
                ]
            }
        ]
    },
    {
        course_id: 'U6C3',
        title: 'Contemporary Design Theory',
        subjects: [
            {
                id: 1,
                title: '|ARCH 2021| - Project 1 - Journal',
                thumbnail: 'T631.png',
                imageURLs: [
                    'U6C3/631-1.jpg', 'U6C3/631-2.jpg', 'U6C3/631-3.jpg', 'U6C3/631-4.jpg', 'U6C3/631-5.jpg', 'U6C3/631-6.jpg', 'U6C3/631-7.jpg', 'U6C3/631-8.jpg', 'U6C3/631-9.jpg', 'U6C3/631-10.jpg'
                ],
                text: [
                    '\nAssignment 1 takes the form of an individually assessed, tutorial group task, conducted in-class each week. In this assignment you will conduct weekly research based \
upon the 7 (seven) content lectures and set mandatory readings from each Lecture. I will apply the knowledge I have gained from each lecture/period and its supporting \
readings, to select an architectural project from the period I am researching that exemplifies the specific theoretical concepts of that period. \n1 > ¬¬Select a project \
to research from the options listed on the course calendar that demonstrates the theory and its concepts of that period \n2 > On the top-left of the sheet, list the \
following: Student Name and ID - Theory/period being researched/Name of the Architect/Name of the Building/Dates of the building\'s design-to-construction/Location of the \
Building, City and Country \n3 > Identify and summarise 3 (three) key concepts as bullet points that define the theory/period of no more than 30 words per concept. \n4 > \
Describe how each of the key concepts above each were applied through the building I have selected in 3 (three) new bullet points of no more than 30 words each. \n5 > \
Use the provided Charles Jencks\' Evolutionary Tree diagram and add my case study and its author to the field. Each week this sheet should be updated so that it accumulates \
all 7 (seven) of the case studies and their authors.'
                ]
            },
            {
                id: 2,
                title: '|ARCH 2021| - Project 2 - Memorial Interview',
                thumbnail: 'T632.png',
                imageURLs: [

                ],
                text: [
                    '\nThis assignment asks me to be both interviewer and interviewee, using your ARCH3050 studio project as the focus of your discussion. \nIn both cases, I will be scaffolding \
my argument about the memorial project under investigation, through the weeky readings and literature presented this semester in ARCH2021 Contemporary Design Theory; \
specifically in relation to concepts and theories of the \'presence and absence\' in post-structuralism and deconstruction. I am required to demonstrate our understanding \
of deconstruction\'s key concepts; for example, using post-structuralism and deconstruction as a means through which to understand architectural language and/or meaning through \
the differ of signification. How does this influence our experience of the work and its interpreted meaning? \nMemorial Interview Questions: \n-What are the socio-cultural \
issues that your project advocates for? What is its critical voice? \n-What is the premise of your project? \n-Where is your project? \n-Why is your project sited there? \n-What \
are the benefits of siting there \n-What are the primary issues and challenges that the site and your project face? \n-What theories does your ADS scheme apply from CDT? \n-How \
does it apply these theories? \n-Who is this project for? \n-Which community or communities does this project respond to?Historically?Now?In the future? \n-How is Identity \
important in your scheme? \n-What are the contemporary architectural precedent projects that apply Identity? \n-What concepts and design methods/tactics are you drawing from \
these Identity-based -contemporary architectural precedent in your ADS scheme? \n-How does this project address individual identity? / How does this project address collective \
identities? \n-How has your project engaged with identity? Whose? \n-How is Memory important in your scheme? \n-What are the contemporary architectural precedent projects that \
apply Memory? \n-What concepts and design methods/tactics are you drawing from these Memory-based contemporary architectural precedent in your ADS scheme? \n-How does this \
project address individual memory? / How does this project address collective memory? \n-How has your project engaged with memory? Whose? \n-How is Memorialisation as a \
performance important in your scheme? \n-Why (if at all) do you feel memorialisation is important? \n-How does your project reflect your reasoning? \n-What are the contemporary \
architectural precedent projects that apply Memorialisation as performance? \n-What concepts and design methods/tactics are you drawing from these Memorialisation-based \
contemporary architectural precedent in your ADS scheme? \n-How do you reconcile between individual and collective memorialisation? \n-How have you applied Memorialisation \
as performance in your scheme? \n-How does the project respond to the local identity of its site? \n-How does the project respond to the national identity of the site? \n-How \
might the project be located in a Jenks\' Diagram? \n-What does your analytical diagram say about your scheme and the theory that underpins it? \n-Why should this project exist?'
                ]
            }
        ]
    },
    {
        course_id: 'D1C1',
        title: 'Communication & Media',
        subjects: []
    },
    {
        course_id: 'D1C2',
        title: 'Introductions to Digital Media',
        subjects: []
    },
    {
        course_id: 'D1C3',
        title: 'Hollywood Film & Television',
        subjects: []
    },
    {
        course_id: 'D2C1',
        title: 'Professional & Technical Communication',
        subjects: []
    },
    {
        course_id: 'D2C2',
        title: 'Digital Design Essentials',
        subjects: []
    },
    {
        course_id: 'D2C3',
        title: 'Design Language in Media Arts',
        subjects: []
    }
]