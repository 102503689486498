const Strings = {
    en: {
        bacP: `Bachelor of Architectural Studies`,
        dipP: 'Diploma of Arts',
        masP: 'Master of Architecture',
        sem: 'Sem',
        UniSem1_1: 'Design Studio 1',
        UniSem1_2: 'Design Construction Principles',
        UniSem1_3: 'Design, Culture, Environment',
        UniSem2_1: 'Architectural Design Studio 2',
        UniSem2_2: 'Architectural Documentation',
        UniSem2_3: 'Architectural & Environment',
        UniSem3_1: 'Architectural Design Studio 3',
        UniSem3_2: 'Design Communication Digital',
        UniSem3_3: 'Architecture & Modernity ',
        UniSem4_1: 'Architectural Design Studio 4',
        UniSem4_2: 'Advanced Design Media',
        UniSem4_3: 'Design Construction Multistorey ',
        UniSem5_1: '',
        UniSem5_2: '',
        UniSem5_3: '',
        UniSem6_1: 'Architectural Design Studio 6',
        UniSem6_2: 'Architecture & Ecology',
        UniSem6_3: 'Contemporary Design Theory',
        DipSem1_1: 'Communication & Media',
        DipSem1_2: 'Introductions to Digital Media',
        DipSem1_3: 'Hollywood Film & Television',
        DipSem2_1: 'Professional & Technical Communication',
        DipSem2_2: 'Digital Design Essentials',
        DipSem2_3: 'Design Language in Media Arts',
    },
    vi: {
        bacP: `Cử Nhân Kiến Trúc`,
        dipP: 'Chứng Chỉ Nghệ Thuật',
        masP: 'Thạc Sĩ Kiến Trúc',
        sem: 'Kì',
        UniSem1_1: '',
        UniSem1_2: '',
        UniSem1_3: '',
        UniSem2_1: '',
        UniSem2_2: '',
        UniSem2_3: '',
        UniSem3_1: '',
        UniSem3_2: '',
        UniSem3_3: '',
        UniSem4_1: '',
        UniSem4_2: '',
        UniSem4_3: '',
        UniSem5_1: '',
        UniSem5_2: '',
        UniSem5_3: '',
        UniSem6_1: '',
        UniSem6_2: '',
        UniSem6_3: '',
        DipSem1_1: '',
        DipSem1_2: '',
        DipSem1_3: '',
        DipSem2_1: '',
        DipSem2_2: '',
        DipSem2_3: ''
    }
}

export function getString(str: string, lang: number): string {
    switch (lang) {
        case 1:
            return Strings.en[str];
        case 2:
            return Strings.vi[str];
    }
}