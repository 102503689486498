import { LangContext } from 'App';
import { getString } from 'model/Strings';
import React, { useContext } from 'react';
import { Semester, Semesters } from 'model/project';
import { useNavigate  } from "react-router-dom";

interface BottomTabProps {
    title: string;
    tabIndex: number;
    SetTabIndex: React.Dispatch<React.SetStateAction<number>>
}

const BottomTab = (props: BottomTabProps) => {
    const [MouseEntered, SetMouseEntered] = React.useState(null);

    const navigate = useNavigate();
    const Tab = Semesters.find(ele => ele.id === props.tabIndex);

    return (
        <div className='Tab' data-mouseenter={MouseEntered}  onMouseEnter={() => SetMouseEntered(true)} onMouseLeave={() => SetMouseEntered(false)}>
            <div className='TabAlter' onClick={() => {navigate('/projects'); props.SetTabIndex(props.tabIndex);}}></div>
            <img src={require(`../asset/images/${Tab.thumbnail}`)} alt="" />
            <div className='centeredText'>{props.title}</div>
        </div>
    )
}

interface BottomTabsProps {
    NavBarExpanded: boolean;
    SetTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export const BottomTabs = (props: BottomTabsProps) => {
    const Lang = useContext(LangContext);
    const [Expanded, SetExpanded] = React.useState(true);

    return (
        <div className='BottomTabs' data-expanded={props.NavBarExpanded} data-close={!Expanded}>
            <BottomTab title={getString('dipP', Lang)} tabIndex={1} SetTabIndex={(val) => { props.SetTabIndex(val); }}/>
            <BottomTab title={getString('bacP', Lang)} tabIndex={2} SetTabIndex={props.SetTabIndex}/>
            <BottomTab title={getString('masP', Lang)} tabIndex={3} SetTabIndex={props.SetTabIndex}/>
        </div>
    );
}