import React, { useLayoutEffect } from 'react';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import './App.scss';
import { NavBar } from './components/NavBar';
import { BottomTabs } from './components/BottomTabs';
import logo from 'asset/images/ThuanAnLogo.png';
import aboutme from 'asset/images/aboutme.png';
import homeweb1 from 'asset/images/HOME_WEB_1.jpg';
import homeweb2 from 'asset/images/HOME_WEB_2.jpg';
import homemobile from 'asset/images/HOME.png';
import { ProjectsTab } from 'components/ProjectsTab';
import { RightNav } from 'components/RightNav';

const VERSION = 1.2;

export const LangContext = React.createContext(null);

const About = () => {
  return (
    <div className='AboutMe'>
      <img src={aboutme} alt="" />
    </div>
  )
}

const Home = () => {
  const innerWidth = window.innerWidth;

  useLayoutEffect(() => {
    let slideIndex = 0;
    if (innerWidth > 900) {
      showSlides();
    }

    function showSlides() {
      let i;
      let slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLDivElement>;
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) { slideIndex = 1 }
      slides[slideIndex - 1].style.display = "block";
      setTimeout(showSlides, 2000); // Change image every 2 seconds
    }
  }, [innerWidth])

  return (
    <div className='Home'>
      {innerWidth > 900 ?
        <>
          <div className="mySlides fade">
            <img src={homeweb1} alt="" />
          </div>

          <div className="mySlides fade">
            <img src={homeweb2} alt="" />
          </div>
        </>
        :
        <div className="mobile">
          <img src={homemobile} alt="" />
        </div>
      }

    </div>
  )
}

const Sketches = () => {
  return (
    <div className='AboutMe'>
      <span>Coming Soon</span>
    </div>
  )
}

function App() {
  const [Language, SetLanguage] = React.useState<number>(1);
  const [Expanded, SetExpanded] = React.useState<boolean>(false);
  const [TabIndex, SetTabIndex] = React.useState(null);
  const [SelectedCourseID, SetSelectedCourseID] = React.useState(null);
  const location = useLocation();

  // TODO: This logs every render, move out
  console.log('Version: ', VERSION);

  return (
    <LangContext.Provider value={Language}>
      <div className='App'>
        <img src={logo} alt="" className='Logo' />

        <NavBar
          Expanded={Expanded}
          SetExpanded={SetExpanded}
        />

        <div className='MainContent'>
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/sketches" element={<Sketches />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={
              <ProjectsTab
                TabIndex={TabIndex}
                SelectedCourseID={SelectedCourseID}
              />} />

          </Routes>
          <RightNav Index={TabIndex} CourseID={SelectedCourseID} ChangeCourse={(CourseID) => SetSelectedCourseID(CourseID)} />
        </div>
        {(location.pathname === '/' || location.pathname === '/projects') &&
          <BottomTabs
            NavBarExpanded={Expanded}
            SetTabIndex={SetTabIndex}
          />
        }
      </div>
    </LangContext.Provider>
  );
}

export default App;
