import React from 'react';
import { Courses, Subject } from 'model/project';
import { faArrowLeftLong } from '@fortawesome/pro-thin-svg-icons/faArrowLeftLong'
import { faChevronUp } from '@fortawesome/pro-thin-svg-icons/faChevronUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface ProjectsTabProps {
    // CourseID: string;
    TabIndex: number;
    SelectedCourseID: string;
}

export const ProjectsTab = (props: ProjectsTabProps) => {
    // const [SelectedCourseID, SetSelectedCourseID] = React.useState(null);
    const [SelectedProjectID, SetSelectedProjectID] = React.useState(null);
    const [ScrollVisible, SetScrollVisible] = React.useState(false);

    const ScrollBtnRef = React.useRef<HTMLButtonElement>(null);
    const Course = Courses.find(ele => ele.course_id === props.SelectedCourseID);

    React.useEffect(() => {
        // Button is displayed after scrolling for 500 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                SetScrollVisible(true);
            } else {
                SetScrollVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const SelectedProject = React.useMemo(() => {
        if (!SelectedProjectID) {
            return null;
        }

        const subject = Course.subjects.find(ele => ele.id === SelectedProjectID) as Subject;
        if (!subject.imageURLs) {
            subject.imageURLs = [];
        }
        return subject;
    }, [Course?.subjects, SelectedProjectID]);

    return (
        <div className='ProjectsTab'>
            {SelectedProject &&
                <div className='Back'>
                    <button title='Back' onClick={() => SetSelectedProjectID(null)} >
                        <FontAwesomeIcon className='icon' icon={faArrowLeftLong as IconDefinition} />
                    </button>
                </div>
            }

            {SelectedProject && ScrollVisible &&
                <button ref={ScrollBtnRef} className='ScrollTopBtn' onClick={() => scrollTop()} >
                    <FontAwesomeIcon className='icon' icon={faChevronUp as IconDefinition} />
                </button>
            }

            {Course &&
                <>
                    <div className='Header'>
                        {SelectedProject ?
                            <>{SelectedProject.title}</>
                            : <>{Course.title}</>
                        }
                    </div>
                    {SelectedProject ?
                        <div className='Assignment'>
                            <div className='Text'>
                                {SelectedProject.text && SelectedProject.text.map((paragraph, i) => {
                                    const blocks = paragraph.split('\n');
                                    let header = '';
                                    let text = blocks;
                                    if (blocks.length > 1) {
                                        header = blocks.splice(0, 1)[0];
                                        text = blocks;
                                    }

                                    return (
                                        <div key={i} className='Paragraph'>
                                            {header &&
                                                <div className='TextHeader'>
                                                    {header}
                                                </div>
                                            }
                                            {text.map((ele, i) => {
                                                return (
                                                    <div key={i} className='TextBlock'>{ele}</div>
                                                );
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='Images'>
                                {SelectedProject.imageURLs.map(imageUrl => {
                                    return (
                                        <img key={imageUrl} className='projectImage' src={require(`../asset/images/${imageUrl}`)} alt="" />
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <>
                            {Course.subjects.length === 0 ?
                                <div className='contentPlaceholder'>Coming Soon</div>
                                :
                                <div className='MainArea'>
                                    {Course.subjects.map(ele => {
                                        return (
                                            <div key={ele.id} className='Project' onClick={() => SetSelectedProjectID(ele.id)}>
                                                <div className='ProjectThumbnail'>
                                                    {ele.thumbnail &&
                                                        <img className='projectThumbnail' src={require(`../asset/images/THUMB/${ele.thumbnail}`)} alt="" />
                                                    }
                                                </div>
                                                <div className='ProjectTitle'>
                                                    {ele.title ?
                                                        <>
                                                            {ele.title?.split(' - ').map(str => {
                                                                return (
                                                                    <div key={str} className='TitlePart'>{str}</div>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <span>Coming Soon</span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}


                                </div>
                            }
                        </>
                    }
                </>
            }
            {/* <RightNav Index={props.TabIndex} CourseID={SelectedCourseID} ChangeCourse={(CourseID) => SetSelectedCourseID(CourseID)} Back={() => SetSelectedProjectID(null)} /> */}

        </div>
    );
}