import React, { useContext } from 'react';
import { Semester, Semesters } from 'model/project';
import { LangContext } from 'App';
import { getString } from 'model/Strings';

const TOTAL_BARS = 15;

interface RightNavProps {
    CourseID: string;
    Index: number;
    ChangeCourse: (CourseID: string) => void;
    // Back: () => void;
}

export const RightNav = (props: RightNavProps) => {
    const Lang = useContext(LangContext);

    const [Open, SetOpen] = React.useState(!!props.Index);
    const [SelectedSemester, SetSelectedSemester] = React.useState(null);

    let Bars = [];
    for (let i = 0; i < TOTAL_BARS; i++) {
        Bars.push(i);
    }

    const SelectedTab: Semester = React.useMemo(() => {
        if (!props.Index) {
            return null;
        }
        let projects = [...Semesters];
        const selectedTab = projects.find(ele => ele.id === props.Index)
        return selectedTab;
    }, [props.Index])

    React.useEffect(() => {
        if (props.Index) {
            setTimeout(() => {
                SetOpen(false);
            }, 3000);

            setTimeout(() => {
                SetOpen(true);
            }, 1000);
        }
        // SetOpen(!!props.Index);
    }, [props.Index])

    const GenerateCourseID = React.useCallback((semID: number, num: number) => {
        let returning = '';
        const SelectedDegree = Semesters.find(ele => ele.id === props.Index);
        if (SelectedDegree) {
            switch (SelectedDegree.text) {
                case 'Uni':
                    returning += 'U';
                    break;
                case 'Dip':
                    returning += 'D';
                    break;
            }
        }

        returning = returning + semID + 'C' + num;
        return returning;
    }, [props.Index]);

    const ChangeCourse = (semID: number, courseNum: number) => {
        const CourseID = GenerateCourseID(semID, courseNum);
        props.ChangeCourse(CourseID);
        // if (SelectedTab && props.CourseID === CourseID) {
        //     props.Back();
        // }
    }

    return (
        <div className='RightNav' data-hasindex={!!props.Index} onMouseEnter={() => SetOpen(true)} onMouseLeave={() => SetOpen(false)} data-open={Open} >
            <div className='OptionContainer'>
                <div className='Options'>
                    {SelectedTab?.children.map(semester => {
                        return (
                            <div key={semester.id} className='Semester' onMouseEnter={() => SetSelectedSemester(semester.id)} onMouseLeave={() => SetSelectedSemester(null)}>
                                {/* <div><span>{`${getString('sem', Lang)} ${semester.id}`}</span></div>  */}
                                {semester.thumbnail &&
                                    <img className='semThumbnail' src={require(`../asset/images/THUMB/${semester.thumbnail}`)} alt="" />
                                }
                                {SelectedSemester === semester.id && SelectedSemester !== 5 &&
                                    <div className='Courses'>
                                        <div className='Course' onClick={() => ChangeCourse(semester.id, 1)}>{getString(semester.text + '_1', Lang)}</div>
                                        <div className='Course' onClick={() => ChangeCourse(semester.id, 2)}>{getString(semester.text + '_2', Lang)}</div>
                                        <div className='Course' onClick={() => ChangeCourse(semester.id, 3)}>{getString(semester.text + '_3', Lang)}</div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className='WaveCont'>
                <div className='MusicWave'>
                    {Bars.map(i => {
                        return (
                            <div key={i} className='bar'></div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}