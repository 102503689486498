import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-thin-svg-icons/faBars'
import { faBarsFilter } from '@fortawesome/pro-thin-svg-icons/faBarsFilter'
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import insta from '../asset/images/instagram.png';
import { Link } from 'react-router-dom';

interface NavBarProps {
    Expanded: boolean;
    SetExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavBar = (props: NavBarProps) => {
    // const [Expanded, SetExpanded] = React.useState(false);
    const [BeginExpandAnimation, SetBeginExpandAnimation] = React.useState(false);

    const fabLinkedinIn = faLinkedinIn as IconProp;

    React.useEffect(() => {
        setTimeout(() => {
            SetBeginExpandAnimation(props.Expanded);
        }, 0.5);
    }, [props.Expanded]);

    return (
        <React.Fragment>
            <div className='NavBar' data-expanded={props.Expanded} data-animate={BeginExpandAnimation}>
                <div className='iconContainer' onClick={() => props.SetExpanded(!props.Expanded)}>
                    <FontAwesomeIcon className='icon Bars' icon={faBars as IconDefinition} />
                    <FontAwesomeIcon className='icon Logo' icon={faBarsFilter as IconDefinition} />
                </div>

                {props.Expanded ?
                    <div className='Menu'>
                        <div className='NavLink'>
                            <Link to="/"><b>Home</b></Link>
                        </div>
                        <div className='NavLink'>
                            <Link to="/sketches"><b>Sketches</b></Link>
                        </div>
                        <div className='NavLink'>
                            <Link to="/about"><b>About</b></Link>
                        </div>
                    </div>
                    :
                    <div className='Media'>
                        <div className='Cover'></div>
                        <div className='Line'></div>
                        <a href='https://www.instagram.com/thuanann' target='_blank' rel='noreferrer'>
                            <img src={insta} alt="" className='brandIcon icon1' />
                        </a>
                        <a href='https://www.instagram.com/thuanann.arch' target='_blank' rel='noreferrer'>
                            <img src={insta} alt="" className='brandIcon icon2' />
                        </a>
                        <a href='https://www.linkedin.com/in/thuanann' target='_blank' rel='noreferrer'>
                            <FontAwesomeIcon className='brandIcon icon3' icon={fabLinkedinIn} />
                        </a>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}